import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"testimonials"} />
		<Helmet>
			<title>
				Testimonials(Feedback) | Neon Nostalgia Bar & Lounge
			</title>
			<meta name={"description"} content={"Hear the Echoes of Joy from the Heartbeats of Our Guests"} />
			<meta property={"og:title"} content={"Testimonials(Feedback) | Neon Nostalgia Bar & Lounge"} />
			<meta property={"og:description"} content={"Hear the Echoes of Joy from the Heartbeats of Our Guests"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/logo_1.jpg?v=2023-10-24T12:33:06.409Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/icon41.png?v=2023-10-24T12:33:50.194Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/icon41.png?v=2023-10-24T12:33:50.194Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/icon41.png?v=2023-10-24T12:33:50.194Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/icon41.png?v=2023-10-24T12:33:50.194Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/icon41.png?v=2023-10-24T12:33:50.194Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/icon41.png?v=2023-10-24T12:33:50.194Z"} />
			<meta name={"msapplication-TileColor"} content={"https://uploads.quarkly.io/6537b4fa47aa25001fb0faae/images/icon41.png?v=2023-10-24T12:33:50.194Z"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" background="--color-darkL2">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 56px 0px"
				width="100%"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--light"
					font="--headline1"
					text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Testimonials(Feedback)
				</Text>
				<Text margin="0px 0px 0px 0px" color="--lightD2" text-align="center" font="--lead">
					Discover the moments and memories our visitors have cherished and the reasons why Neon Nostalgia Bar & Lounge remains their favourite hangout spot.
				</Text>
			</Box>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				align-items="stretch"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="16px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
			>
				<Box
					margin="0px 30px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					lg-padding="0px 15px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					sm-margin="40px 0px 0px 0px"
					display="flex"
					flex-direction="column"
				>
					<Text margin="0px 0px 0px 0px" color="--orange" font="--headline1">
						“
					</Text>
					<Text margin="0px 0px 20px 0px" color="--lightD2" font="--lead" flex="1 0 auto">
						"Every visit to Neon Nostalgia takes me on a trip down memory lane. The atmosphere, the music, and those signature neon drinks are just unparalleled. A perfect blend of the past with the comforts of the present."
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start">
						<Box>
							<Text color="--light" font="--headline3" margin="0px 0px 2px 0px">
								Sophie
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					margin="0px 30px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					lg-padding="0px 15px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					sm-margin="40px 0px 0px 0px"
					display="flex"
					flex-direction="column"
				>
					<Text margin="0px 0px 0px 0px" color="--orange" font="--headline1">
						“
					</Text>
					<Text margin="0px 0px 20px 0px" color="--lightD2" font="--lead" flex="1 0 auto">
						"The arcade corner is an absolute gem! It's hard to find places that bring back such authentic gaming memories. This bar isn't just about drinks; it's about experiences, conversations, and a whole lot of fun."
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start">
						<Box>
							<Text color="--light" font="--headline3" margin="0px 0px 2px 0px">
								Mike
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					margin="0px 30px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					lg-padding="0px 15px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					sm-margin="40px 0px 0px 0px"
					display="flex"
					flex-direction="column"
				>
					<Text margin="0px 0px 0px 0px" color="--orange" font="--headline1">
						“
					</Text>
					<Text margin="0px 0px 10px 0px" color="--lightD2" font="--lead" flex="1 0 auto">
						"Neon Nostalgia is like a page from a retro magazine come to life. The ambiance is a beautiful reminder of an era gone by, and the staff makes you feel right at home. A must-visit for anyone seeking genuine connections."
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start">
						<Box>
							<Text color="--light" font="--headline3" margin="0px 0px 2px 0px">
								Eleanor
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					margin="0px 30px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					lg-padding="0px 15px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					sm-margin="40px 0px 0px 0px"
					display="flex"
					flex-direction="column"
				>
					<Text margin="0px 0px 0px 0px" color="--orange" font="--headline1">
						“
					</Text>
					<Text margin="0px 0px 10px 0px" color="--lightD2" font="--lead" flex="1 0 auto">
						"From the moment I stepped in, I felt the vibes of the '80s. The music, the décor, and especially the cocktails, everything screams nostalgia. It's my go-to place to relax, reminisce, and relive some of the best times."
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start">
						<Box>
							<Text color="--light" font="--headline3" margin="0px 0px 2px 0px">
								Liam
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-darkL2">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="0px 160px 0px 0px"
				border-radius="24px"
				margin="0px 0px 0px 0px"
				width="90%"
				lg-padding="0px 50px 0px 0px"
				md-padding="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				md-margin="0px 0px 60px 0px"
				md-align-items="center"
			>
				<Text
					margin="0px 0px 40px 0px"
					font="--base"
					color="--light"
					md-text-align="center"
					md-margin="0px 0px 20px 0px"
				>
					We cherish each memory, story, and review shared by our guests. Your experiences fuel our passion. Looking forward to creating more neon-lit memories together at Neon Nostalgia Bar & Lounge.
				</Text>
				<Link
					href="/contacts"
					text-decoration-line="initial"
					color="--light"
					font="--lead"
					hover-opacity="0.9"
					background="--color-orange"
					border-radius="8px"
					padding="11px 24px 12px 24px"
				>
					Contact Us
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652fe240cd81ee001f7dd8c4"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});